import React, {useState} from 'react'
import styled from "styled-components"
import Layout from '../components/layout'
import { Link } from "gatsby"

const ProjectDescriptionBox = styled.div`
  background-color: white;
  position: relative;
  padding: 2em;
  border-radius: 7px;
  box-shadow: 0px 5px 10px 1px #444;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  z-index: 3;
`;

const ProjectDescriptionBackground = styled.div`
  background-color: rgba(0,0,0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  body {
    scroll: none;
  }
  transition: 0.3s;
`;

const ProjectDescription = ({visible, onClickOutside, children}) => {
  return <ProjectDescriptionBackground style={{opacity: visible ? 1 : 0, zIndex: visible ? 100 : -1}} onClick={onClickOutside}>
    <ProjectDescriptionBox>
      {children}
    </ProjectDescriptionBox>
  </ProjectDescriptionBackground>
}

const ProjectBox = styled.div`
    position: relative;
    min-height: 100px;
    transition: 0.3s;
    .background {
      border-radius: 5px;
      box-shadow: 0px 5px 10px 1px #111111;
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      transition: 0.3s;
    }
    &:hover {
      transform: scale(1.2, 1.2);
      z-index: 1;
    }
    .title {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: column;

      text-align: center;
      font-size: 1.1em;
      font-family: sans;
      height: 100%;
      position: absolute;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0);
      border: 0;
      &:hover {
        text-decoration: underline;
        color: white;
      }
      .title-text {
        vertical-align: bottom;
        transition: 0.3s;
        width: 100%;
        margin-bottom: 10px;
      }
    }
`;

const ProjectBoxLed = styled(ProjectBox)`
  color: white;
  .background {
    background-image: url(/images/led.jpeg);
    background-position: 50% 50%;
    filter: hue-rotate(90deg) contrast(20%);
    background-size: 300%;
  }
  &:hover {
    .background {
      filter: hue-rotate(90deg) contrast(100%);
    }
    .title-text {
      background-color: rgba(64,64,64,1)
    }
  }
`;

const ProjectBoxCardano = styled(ProjectBox)`
  color: white;
  .background {
    background-image: url(/images/cardano-logo.jpg);
    background-position: 50% -10%;
    filter: contrast(20%);
    background-size: 70%;
  }
  &:hover {
    .background {
      filter: contrast(100%);
    }
    .title-text {
      background-color: rgba(64,64,64,1)
    }
  }
`;

const ProjectBoxStockMarket = styled(ProjectBox)`
  color: white;
  .background {
    background-image: url(/images/stock-market.jpg);
    background-position: 50% -10%;
    filter: contrast(20%);
    background-size: 70%;
  }
  &:hover {
    .background {
      filter: contrast(100%);
    }
    .title-text {
      background-color: rgba(64,64,64,1)
    }
  }
`;

const ProjectBoxMicroservices = styled(ProjectBox)`
  color: white;
  .background {
    background-image: url(/images/microservices.jpg);
    background-position: 50% 50%;
    filter: contrast(20%);
    background-size: 70%;
  }
  &:hover {
    .background {
      filter: contrast(100%);
    }
    .title-text {
      background-color: rgba(64,64,64,1)
    }
  }
`;

const ProjectBoxBlank = styled(ProjectBox)`
  color: #333;
  .background {
    background-color: white;
  }
  &:hover {
    .title-text {
      background-color: rgba(64,64,64,1);
      color: white;
    }
  }
`;

const Project = ({ title, image, children }) => {
  const [showDetails, setShowDetails] = useState(false);
  const toggleDescription = () => {
    setShowDetails(!showDetails)
  }
  const BoxComponent = (function() {
    if(image === 'led') {
      return ProjectBoxLed;
    } else if(image === 'cardano') {
      return ProjectBoxCardano;
    } else if(image === 'stock-market') {
      return ProjectBoxStockMarket;
    } else if(image === 'microservices') {
      return ProjectBoxMicroservices;
    } else {
      return ProjectBoxBlank;
    }
  }())
  return (
    <li>
      <BoxComponent>
        <div class='background' />
        <button class='title' onClick={toggleDescription}><span class='title-text'>{title}</span></button>
      </BoxComponent>
      <ProjectDescription visible={showDetails} onClickOutside={toggleDescription}>
        <h1>{title}</h1>
        {children}
      </ProjectDescription>
    </li>
  )
}

const OssLink = ({ href, children }) => (
  <li>
    <a href={href}>{children}</a>
  </li>
)

//const ArticleLink = ({ href, children }) => (
//  <li>
//    <a href={href}>{children}</a>
//  </li>
//)

const Projects = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 1rem;

  li {
    list-style-type: none;
  }
`;

const PortfolioPage = () => (
  <Layout
    metaDescription="Javier Casas' Portfolio: projects that showcase what I can do"
    metaKeywords="javier, casas, software, development, finance, functional, programming"
  >
    <h1>Portfolio</h1>
    <h2>Full Stack Web Engineering</h2>
    <Projects>
      <Project title="Datatype Bridge" image="led">
        <p>
          A system for extending the types from a microservices-based backend to
          a PureScript frontend, including routes, JSON encoding, sample data,
          and Eq/Ord instances. It automatically detects when the frontend is no
          longer up to date and raises warnings to prevent broken builds. It has
          been in use since Jan 2017, and has been extended with support for
          auto-detecting instance types recently. See some details at:
        </p>
        <p>
          <a href="https://www.stackbuilders.com/tutorials/functional-full-stack/purescript-bridge/">
            Connecting a Haskell Backend to a PureScript Frontend
          </a>
        </p>
      </Project>
      <Project title="Common Redux Mistakes Series" image="led">
        <p>
          Redux is a powerful tool, but we not always use it the right way. This is a
          series of articles on the common mental shortcuts we take with Redux
          and how these shortcuts affect code quality. This list is currently being used
          as a basis for understanding and teaching advanced Redux usage in some companies.
        </p>
        <ul>
          <li><Link to='/articles/3-common-beginner-react-redux-mistakes'>3 Common Beginner React/Redux mistakes</Link></li>
          <li><Link to='/articles/3-common-intermediate-react-redux-mistakes'>3 Common Intermediate React/Redux mistakes</Link></li>
          <li><Link to='/articles/3-common-advanced-react-redux-mistakes'>3 Common Advanced React/Redux mistakes</Link></li>
        </ul>
      </Project>
    </Projects>

    <h2>Software Architecture</h2>
    <Projects>
      <Project title="Sensible Steps into Microservices" image="microservices">
        <p>
          The Microservices Architecture has been sold as the panacea for scaling startups,
          constructing clean code, simplifying communication, and making everything easier.
          If it's so great, why is that so many startups based on Microservices have closed?
          Why is it that Netflix and Amazon only transitioned to Microservices after
          making lots of money with a Monolithic Architecture? This article explains the dark
          side of going to Microservices without applying some critical thinking.          
        </p>
        <ul>
          <li><Link to='/articles/sensible-steps-to-microservices'>Sensible Steps into Microservices</Link></li>
          
        </ul>
      </Project>
      <Project title='Serverless: La evolucion de la arquitectura a la nube (Spanish)' image='microservices'>
        <p>
          On this talk, I explore how the Serverless framework can be used to construct applications,
          as well as the advantages and disadvantages of constructing systems this way.
        </p>
        <a href='https://youtu.be/o9oZVj6bdrk'>Watch on Youtube: Serverless: La evolucion de la arquitectura a la nube</a>
      </Project>
    </Projects>

    <h2>Cryptocurrencies & Cardano</h2>
    <Projects>
      <Project title="GiG Economy Token Prototype" image="cardano">
        <p>
          As part of a prototype to construct a cryptocurrency based on the Cardano blockchain,
          I implemented a system for simulating interactions in a smart-contract based applications.
          The code has been released in Github:
        </p>
        <a href='https://github.com/gig-economy-token/gig-economy'>The GiG Economy Token Prototype</a>
      </Project>
      <Project title="GiG Economy Whitepaper" image="cardano">
        <p>
          A whitepaper explaining how the prototype works, as well as how can it be used and evolved in the future.
        </p>
        <a href='https://github.com/gig-economy-token/White-Paper'>The GiG Economy Token White Paper</a>
      </Project>
      <Project title="A pub-sub implementation in the Plutus Smart Contract language" image="cardano">
        <p>
          The Plutus programming language allows to write smart contracts in the Cardano platform.
          For the most part, these smart contracts are about sending information and processing
          such information in the blockchain. But, no one attempted to retrieve information from
          the blockchain before. With this exploration, I found a way to send messages
          into channels, and retrieve such messages. All of it encoded in the Cardano blockchain.
        </p>
        <ul>
          <li><a href='https://github.com/gig-economy-token/plutus-pubsub-paper'>White paper explaining how the mechanism works</a></li>
          <li><a href='https://github.com/gig-economy-token/plutus-unlift'>plutus-unlift: A small library implementing the ideas on the Plutus pub-sub paper</a></li>
        </ul>
      </Project>
    </Projects>

    <h2>Computational Finance</h2>
    <Projects>
      <Project title="Interconnection with brokerages" image='stock-market'>
        <p>
          A system for interconnecting with several online brokerages that
          allows easily sending orders to the market and enables the system to
          be able to execute in under 20 seconds what usually takes several
          hours. It includes sending orders to Interactive Brokers and Matrix Brokers,
          as well as creating "unwind" portfolios for closing positions after
          the corresponding stock options expire. 
        </p>
        <p>
          This was part of a project for a client, therefore no source code is available. 
        </p>
      </Project>
      <Project title="Smart Synthetic Algorithm" image='stock-market'>
        <p>
          A system that automatically converts some stock options to synthetic
          stock options, and keeps track of the conversion on the system in
          order to minimise the implied volatility of the resulting portfolio.
        </p>
        <p>
          This was part of a project for a client, therefore no source code is available. 
        </p>
      </Project>
    </Projects>

    <h2>Open Source</h2>
    <Projects>
      <Project title="elm-decimal" image='led'>
        <p>
        A library for the Elm programming language that allows manipulating numbers with infinite precision.
        The library has been forked, and is now part of the official list of Elm packages.
        </p>
        <OssLink href="https://github.com/javcasas/elm-decimal">
          elm-decimal: a library for manipulating infinite-precision decimal numbers
        </OssLink>
        <OssLink href="https://package.elm-lang.org/packages/prikhi/decimal/latest/">
          "decimal" at the Elm Package Repository
        </OssLink>
      </Project>
      <Project title="mpicdude" image='led'>
        <p>
          mpicdude: General purpose programmer for Pic24/Pic32 microcontrollers
        </p>
        <OssLink href="https://github.com/javcasas/mpicdude">
          mpicdude: General purpose programmer for Pic24/Pic32 microcontrollers
        </OssLink>
      </Project>
    </Projects>

  </Layout>
)

export default PortfolioPage
